import React from "react";
import Select from "react-select";
import { _ as __} from 'ttag';
class DynamicAdd extends React.PureComponent {
    render() {
        return (
            <>
                {this.props.show_select === true ? (
                    <div>
                        <h3>{__('select offer')}</h3>
                        <div className="lastsection_content" ref={this.props.wrapperRef}>
                            <Select
                                className="dynamicadd"
                                value={this.props.selectedOption}
                                onChange={this.props.handleChange}
                                options={this.props.offer_options}
                                onClose={this.props.closeshowDropdown}
                                formatOptionLabel={this.props.formatOptionLabel}
                                defaultMenuIsOpen={true}
                            />
                        </div>
                    </div>
                ) : (
                        ""
                )}
            </>
        );
    }
}

export default DynamicAdd;
