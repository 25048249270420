import React from "react";
import { ClipLoader } from "react-spinners";
import { css } from "@emotion/core";

const override = css`
display: block;
margin: 0 auto;
border-color: '#bcf9bc';
`;

class LoaderSection extends React.PureComponent {
    render() {
        return (
            <>
                <div className="sweet-loading">
                    <ClipLoader css={override} size={35} color={"#dedede"} loading={this.props.createOfferLoader} />
                </div>
            </>
        );
    }
}

export default LoaderSection;
