var ajaxUrl, api_url, iFrame_url, api_key; 

var hostname = window.location.hostname;
if (hostname === "localhost") {
    ajaxUrl = `http://localhost/stilyo-apps/reconvert`;
    api_url = `http://localhost:4000`;
    iFrame_url = `http://localhost/stilyo-apps/reconvert`;
}
else if (hostname === "dev-editor.stilyoapps.com" || hostname === "dev.stilyoapps.com") {
    ajaxUrl = `https://dev.stilyoapps.com/reconvert`;
    // api_url = `https://dev-rct-api.stilyoapps.com`;
    api_url = `https://dev-rct-argo.stilyoapps.com`;
    iFrame_url = `https://dev.stilyoapps.com/reconvert`;
}
else if (hostname === "demo-editor.stilyoapps.com" || hostname === "demo.stilyoapps.com") {
    ajaxUrl = `https://demo.stilyoapps.com/reconvert`;
    api_url = `https://demo-node.stilyoapps.com`;
    iFrame_url = `https://demo.stilyoapps.com/reconvert`;
}
else if (hostname === "test-php.stilyoapps.com") {
    ajaxUrl = `https://test-php.stilyoapps.com/reconvert`;
    // api_url = `https://dev-rct-api.stilyoapps.com`;
    api_url = `https://test-node.stilyoapps.com`;
    iFrame_url = `https://test-php.stilyoapps.com/reconvert`;
} else if (hostname === "reconvert-stage.stilyoapps.com" ) {
    ajaxUrl = "https://reconvert-stage.stilyoapps.com";
    api_url = "https://stage-node.stilyoapps.com";
    iFrame_url = "https://reconvert-stage.stilyoapps.com"
} else if (hostname === "stage-reconvert-ng.stilyoapps.com" || hostname === "staging.funnels.reconvert.com") {
    ajaxUrl = "https://stage-reconvert-ng.stilyoapps.com/reconvert";
    api_url = "https://stage-node-ng.stilyoapps.com";
    iFrame_url = "https://stage-reconvert-ng.stilyoapps.com/reconvert"
} else if (hostname === "www.stilyoapps.com" || hostname === "stilyoapps.com" || hostname === "cdn.stilyoapps.com" || hostname === "funnels.reconvert.com") {
    ajaxUrl = `https://www.stilyoapps.com/reconvert`;
    api_url = `https://rct-service.stilyoapps.com`;
    iFrame_url = `https://www.stilyoapps.com/reconvert`;
} else if (hostname === "prephp.stilyoapps.com") {
    ajaxUrl = `https://prephp.stilyoapps.com/reconvert`;
    api_url = `https://prenode.stilyoapps.com`;
    iFrame_url = `https://prephp.stilyoapps.com/reconvert`;
}
else {
    ajaxUrl = `http://localhost/stilyo-apps/reconvert`;
    api_url = `http://localhost:4000`;
    iFrame_url = `http://localhost/stilyo-apps/reconvert`;
    api_key = "6ffac6fcc65d954bbafaec87cb54b32f"
}

module.exports = {
    iFrame_url: iFrame_url,
    api_url: api_url,
    ajaxUrl: ajaxUrl,
    api_key: api_key,
};
