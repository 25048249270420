import React from "react";
import "./App.css";
/* BrowserRouter as Router, */
import { Switch, Route, HashRouter } from "react-router-dom";
import Home from "./components/Home";

function App() {
  if (window.location.hostname !== 'localhost') {
    console.log = function () { }
  }
  return (
    <>
      {
          <React.Fragment>
            <HashRouter>
              <div className="App">
                <Switch>
                  <AppRoutes> </AppRoutes>
                </Switch>
              </div>
            </HashRouter>
          </React.Fragment>

      }
    </>
  );
}
export default App;


const AppRoutes = () => {
  return (
    <>
      <Route exact path="/" component={Home} />
      <Route exact path="/:funnel_id" component={Home} />
    </>
  )
}