import React from "react";
import { _ as __} from 'ttag';
class ActionButton extends React.PureComponent {
    render() {
        return (
            <div className="action_tools">
                <span className="tooltip">
                    <p className="action_wrapper" data-id="edit_offer" onClick={this.props.editOffer}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" className="Polaris-Icon__Svg">
                        <g clipPath="url(#clip0)">
                        <path d="M11.8768 1.12263L14.8784 4.12503L15.3784 3.62423C15.5755 3.42714 15.7318 3.19316 15.8385 2.93566C15.9452 2.67815 16.0001 2.40215 16.0001 2.12343C16.0001 1.8447 15.9452 1.56871 15.8385 1.3112C15.7318 1.05369 15.5755 0.819716 15.3784 0.622628C15.1813 0.42554 14.9473 0.269202 14.6898 0.162539C14.4323 0.0558754 14.1563 0.000976563 13.8776 0.000976562C13.3147 0.000976563 12.7748 0.224591 12.3768 0.622628L11.8768 1.12263ZM13.5 5.50023L10.4984 2.49783L0.9744 12.0226L0 15.9986L3.976 15.025L13.5 5.50023Z" fill="#5C5F62"/>
                        </g>
                        <defs>
                        <clipPath id="clip0">
                        <rect width="16" height="16" fill="white"/>
                        </clipPath>
                        </defs>
                        </svg>
                    </p>
                    <span className="tooltiptext down_tooltip">{__('Edit')}</span>
                </span>
                <span className="tooltip">
                    <p className="action_wrapper" data-id="preview_offer" onClick={this.props.previewOffer}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" className="Polaris-Icon__Svg">
                        <g clipPath="url(#clip0)">
                        <path fillRule="evenodd" clipRule="evenodd" d="M15.9424 7.70298C14.2328 3.42858 10.9448 1.47978 7.6584 1.65098C4.4104 1.81898 1.3448 4.05258 0.0456024 7.73338L-0.0487976 7.99978L0.0456024 8.26618C1.3448 11.947 4.4096 14.1806 7.6584 14.3486C10.9448 14.519 14.2328 12.571 15.9424 8.29658L16.0616 7.99978L15.9424 7.70298ZM8 11.9998C9.06087 11.9998 10.0783 11.5784 10.8284 10.8282C11.5786 10.0781 12 9.06065 12 7.99978C12 6.93891 11.5786 5.9215 10.8284 5.17135C10.0783 4.42121 9.06087 3.99978 8 3.99978C6.93914 3.99978 5.92172 4.42121 5.17158 5.17135C4.42143 5.9215 4 6.93891 4 7.99978C4 9.06065 4.42143 10.0781 5.17158 10.8282C5.92172 11.5784 6.93914 11.9998 8 11.9998Z" fill="#5C5F62"/>
                        <path d="M8.00001 10.4001C9.32549 10.4001 10.4 9.32558 10.4 8.0001C10.4 6.67461 9.32549 5.6001 8.00001 5.6001C6.67452 5.6001 5.60001 6.67461 5.60001 8.0001C5.60001 9.32558 6.67452 10.4001 8.00001 10.4001Z" fill="#5C5F62"/>
                        </g>
                        <defs>
                        <clipPath id="clip0">
                        <rect width="16" height="16" fill="white"/>
                        </clipPath>
                        </defs>
                        </svg>
                    </p>
                    <span className="tooltiptext down_tooltip">{__('Preview')}</span>
                </span>
                <span className="tooltip">
                    <p className="action_wrapper" data-id="delete_offer" onClick={this.props.delete_offer_templateModal}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" className="Polaris-Icon__Svg">
                        <path d="M8 3.994C8 2.893 8.895 2 10 2C11.105 2 12 2.893 12 3.994H16C16.552 3.994 17 4.44 17 4.991C17 5.541 16.552 5.988 16 5.988H4C3.448 5.988 3 5.541 3 4.991C3 4.441 3.448 3.994 4 3.994H8ZM5 14.508V8H7V14.508C7.00053 14.6403 7.05344 14.7669 7.14715 14.8603C7.24086 14.9536 7.36774 15.006 7.5 15.006H9V8H11V15.006H12.5C12.6323 15.006 12.7591 14.9536 12.8528 14.8603C12.9466 14.7669 12.9995 14.6403 13 14.508V8H15V14.508C14.9995 14.8358 14.9344 15.1602 14.8085 15.4629C14.6826 15.7655 14.4983 16.0404 14.2661 16.2718C14.034 16.5032 13.7585 16.6866 13.4555 16.8115C13.1525 16.9365 12.8278 17.0005 12.5 17H7.5C6.12 17 5 15.884 5 14.508Z" fill="#5C5F62"/>
                        </svg>
                    </p>
                    <span className="tooltiptext down_tooltip">{__('Delete')}</span>
                </span>
            </div>
        );
    }
}

export default ActionButton;
