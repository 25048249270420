import { _ as __ } from "ttag";

var category = [
  { value: "all orders", label: __("All orders") },
  { value: "customer", label: __("Customer information") },
  { value: "order", label: __("Order details") },
  { value: "product", label: __("Product purchased data") },
  { value: "other", label: __("Other options") },
];
var customer = [
  { value: "accepts-marketing", label: __("Accepts marketing") },
  { value: "birthday", label: __("Birthday") },
  { value: "customer-note", label: __("Customer note") },
  { value: "customer-created", label: __("Customer created date") },
  { value: "email", label: __("Email") },
  { value: "first-name", label: __("First name") },
  { value: "last-name", label: __("Last name") },
  { value: "number-of-orders", label: __("Number of orders") },
  { value: "phone-number", label: __("Phone number") },
  { value: "shipping-address-1", label: __("Address") },
  { value: "shipping-address-2", label: __("Apartment, suite, etc.") },
  { value: "shipping-city", label: __("City") },
  { value: "shipping-country", label: __("Country") },
  { value: "shipping-province", label: __("State/province") },
  { value: "shipping-zip-code", label: __("Zip code") },
  { value: "survey-answers", label: __("Survey answers") },
  { value: "survey-taken", label: __("Survey taken") },
  { value: "tags", label: __("Tag") },
  { value: "time-since-last-order", label: __("Time since last order") },
  { value: "total-item-bought", label: __("Total items bought") },
  { value: "tax-settings", label: __("Tax settings") },
  { value: "total-revenue-generated", label: __("Total revenue generated") },
  { value: "specific-customers", label: __("Specific customers") },
  { value: "company", label: __("Company") },
];

var order = [
  { value: "billing-address-1", label: __("Billing address") },
  { value: "billing-address-2", label: __("Billing Apartment, suite, etc.") },
  {
    value: "billing-address-first-name",
    label: __("Billing address first name"),
  },
  {
    value: "billing-address-last-name",
    label: __("Billing address last name"),
  },
  { value: "billing-city", label: __("Billing city") },
  { value: "billing-country", label: __("Billing country") },
  { value: "billing-province", label: __("Billing state/province") },
  { value: "billing-zip-code", label: __("Billing zip code") },
  { value: "billing-company", label: __("Billing company") },
  { value: "carrier", label: __("Carrier") },
  { value: "discount-code-used", label: __("Discount code used") },
  { value: "discount-value", label: __("Total discount value") },
  { value: "fraud-analysis", label: __("Fraud analysis") },
  { value: "fulfillment-status", label: __("Fulfillment status") },
  { value: "order-date", label: __("Order date") },
  { value: "order-id", label: __("Order id") },
  { value: "order-name", label: __("Order name") },
  { value: "order-number", label: __("Order number") },
  { value: "order-notes", label: __("Order notes") },
  { value: "order-tags", label: __("Order tags") },
  { value: "order-time", label: __("Order time") },
  { value: "order-value", label: __("Order value") },
  { value: "payment-method", label: __("Payment method") },
  { value: "payment-status", label: __("Payment status") },
  { value: "refund-value", label: __("Refund value") },
  { value: "shipment-status", label: __("Shipment status") },
  { value: "shipping-address-1", label: __("Shipping Address") },
  { value: "shipping-address-2", label: __("Shipping Apartment, suite, etc.") },
  {
    value: "shipping-address-first-name",
    label: __("Shipping address first name"),
  },
  {
    value: "shipping-address-last-name",
    label: __("Shipping address last name"),
  },
  { value: "shipping-city", label: __("Shipping city") },
  { value: "shipping-country", label: __("Shipping country") },
  { value: "shipping-company", label: __("Shipping company") },
  { value: "shipping-province", label: __("Shipping state/province") },
  { value: "shipping-zip-code", label: __("Shipping zip code") },
  { value: "shipping-method", label: __("Shipping method") },
  { value: "shipping-value", label: __("Shipping value") },
  { value: "sales-channel", label: __("Sales channel") },
  { value: "tax-value", label: __("Tax value") },
  { value: "total-items-count", label: __("Total items count") },
  // { value: "total-revenue", label: __("Total revenue") },
  { value: "tracking-number", label: __("Tracking number") },
  { value: "timeline-comments", label: __("Timeline comments") },
  { value: "order-status", label: __("Order status") },
  { value: "currency", label: __("Currency") },
  { value: "language", label: __("Language") },
];
var product = [
  {
    value: "available-at-sales-channel",
    label: __("Available at sales channel"),
  },
  { value: "barcode", label: __("Barcode") },
  {
    value: "charge-taxes-on-this-products",
    label: __("Charge taxes on this products"),
  },
  { value: "compare-at-price", label: __("Compare at price") },
  { value: "product-collection", label: __("Product collection") },
  { value: "specific-product", label: __("Specific product") },
  { value: "harmonizedSystemCode", label: __("Hs (harmonized system) code") },
  { value: "inventory-policy", label: __("Inventory policy") },
  { value: "overselling", label: __("Overselling") },
  { value: "product-description", label: __("Product description") },
  { value: "product-price", label: __("Product price") },
  { value: "product-tags", label: __("Product tags") },
  { value: "product-title", label: __("Product title") },
  { value: "product-type", label: __("Product type") },
  { value: "product-vendor", label: __("Product vendor") },
  { value: "inventory-quantity", label: __("Inventory quantity") },
  { value: "sku", label: __("SKU") },
  { value: "variant-name", label: __("Variant name") },
  { value: "weight", label: __("Weight") },
  { value: "cost-per-item", label: __("Cost per item") },
  {
    value: "physical-product",
    label: __("Physical product \/ Requires shipping"),
  },
];
var other = [
  { value: "operating-system", label: __("Operating system") },
  { value: "browser", label: __("Browser") },
  { value: "device", label: __("Device") },
  { value: "user-impressions-for-this-order", label: __("User impressions for this order") },
  { value: "user-impressions-for-all-orders", label: __("User impressions for all orders") }
];
var countryData = [
  { value: "af", label: ("Afghanistan") },
  { value: "ax", label: ("Aland Islands") },
  { value: "al", label: ("Albania") },
  { value: "dz", label: ("Algeria") },
  { value: "ad", label: ("Andorra") },
  { value: "ao", label: ("Angola") },
  { value: "ai", label: ("Anguilla") },
  { value: "ag", label: ("Antigua And Barbuda") },
  { value: "ar", label: ("Argentina") },
  { value: "am", label: ("Armenia") },
  { value: "aw", label: ("Aruba") },
  { value: "au", label: ("Australia") },
  { value: "at", label: ("Austria") },
  { value: "az", label: ("Azerbaijan") },
  { value: "bs", label: ("Bahamas") },
  { value: "bh", label: ("Bahrain") },
  { value: "bd", label: ("Bangladesh") },
  { value: "bb", label: ("Barbados") },
  { value: "by", label: ("Belarus") },
  { value: "be", label: ("Belgium") },
  { value: "bz", label: ("Belize") },
  { value: "bj", label: ("Benin") },
  { value: "bm", label: ("Bermuda") },
  { value: "bt", label: ("Bhutan") },
  { value: "bo", label: ("Bolivia") },
  { value: "ba", label: ("Bosnia And Herzegovina") },
  { value: "bw", label: ("Botswana") },
  { value: "bv", label: ("Bouvet Island") },
  { value: "br", label: ("Brazil") },
  { value: "io", label: ("British Indian Ocean Territory") },
  { value: "vg", label: ("Virgin Islands,British") },
  { value: "bn", label: ("Brunei") },
  { value: "bg", label: ("Bulgaria") },
  { value: "bf", label: ("Burkina Faso") },
  { value: "bi", label: ("Burundi") },
  { value: "kh", label: ("Cambodia") },
  { value: "cm", label: ("Republic of Cameroon") },
  { value: "ca", label: ("Canada") },
  { value: "cv", label: ("Cape Verde") },
  { value: "bq", label: ("Caribbean Netherlands") },
  { value: "ky", label: ("Cayman Islands") },
  { value: "cf", label: ("Central African Republic") },
  { value: "td", label: ("Chad") },
  { value: "cl", label: ("Chile") },
  { value: "cn", label: ("China") },
  { value: "cx", label: ("Christmas Island") },
  { value: "cc", label: ("Cocos (Keeling) Islands") },
  { value: "co", label: ("Colombia") },
  { value: "km", label: ("Comoros") },
  { value: "cg", label: ("Congo") },
  { value: "cd", label: ("Congo,The Democratic Republic Of The") },
  { value: "ck", label: ("Cook Islands") },
  { value: "cr", label: ("Costa Rica") },
  { value: "hr", label: ("Croatia") },
  { value: "cu", label: ("Cuba") },
  { value: "cw", label: ("Curaçao") },
  { value: "cy", label: ("Cyprus") },
  { value: "cz", label: ("Czech Republic") },
  { value: "ci", label: ("Côte d'Ivoire") },
  { value: "dk", label: ("Denmark") },
  { value: "dj", label: ("Djibouti") },
  { value: "dm", label: ("Dominica") },
  { value: "do", label: ("Dominican Republic") },
  { value: "ec", label: ("Ecuador") },
  { value: "eg", label: ("Egypt") },
  { value: "sv", label: ("El Salvador") },
  { value: "gq", label: ("Equatorial Guinea") },
  { value: "er", label: ("Eritrea") },
  { value: "ee", label: ("Estonia") },
  { value: "sz", label: ("Eswatini") },
  { value: "et", label: ("Ethiopia") },
  { value: "fk", label: ("Falkland Islands (Malvinas)") },
  { value: "fo", label: ("Faroe Islands") },
  { value: "fj", label: ("Fiji") },
  { value: "fi", label: ("Finland") },
  { value: "fr", label: ("France") },
  { value: "gf", label: ("French Guiana") },
  { value: "pf", label: ("French Polynesia") },
  { value: "tf", label: ("French Southern Territories") },
  { value: "ga", label: ("Gabon") },
  { value: "gm", label: ("Gambia") },
  { value: "ge", label: ("Georgia") },
  { value: "de", label: ("Germany") },
  { value: "gh", label: ("Ghana") },
  { value: "gi", label: ("Gibraltar") },
  { value: "gr", label: ("Greece") },
  { value: "gl", label: ("Greenland") },
  { value: "gd", label: ("Grenada") },
  { value: "gp", label: ("Guadeloupe") },
  { value: "gt", label: ("Guatemala") },
  { value: "gg", label: ("Guernsey") },
  { value: "gn", label: ("Guinea") },
  { value: "gw", label: ("Guinea Bissau") },
  { value: "gy", label: ("Guyana") },
  { value: "ht", label: ("Haiti") },
  { value: "hm", label: ("Heard Island And Mcdonald Islands") },
  { value: "hn", label: ("Honduras") },
  { value: "hk", label: ("Hong Kong") },
  { value: "hu", label: ("Hungary") },
  { value: "is", label: ("Iceland") },
  { value: "in", label: ("India") },
  { value: "id", label: ("Indonesia") },
  { value: "ir", label: ("Iran,Islamic Republic Of") },
  { value: "iq", label: ("Iraq") },
  { value: "ie", label: ("Ireland") },
  { value: "im", label: ("Isle Of Man") },
  { value: "il", label: ("Israel") },
  { value: "it", label: ("Italy") },
  { value: "jm", label: ("Jamaica") },
  { value: "jp", label: ("Japan") },
  { value: "je", label: ("Jersey") },
  { value: "jo", label: ("Jordan") },
  { value: "kz", label: ("Kazakhstan") },
  { value: "ke", label: ("Kenya") },
  { value: "ki", label: ("Kiribati") },
  { value: "xk", label: ("Kosovo") },
  { value: "kw", label: ("Kuwait") },
  { value: "kg", label: ("Kyrgyzstan") },
  { value: "la", label: ("Lao People's Democratic Republic") },
  { value: "lv", label: ("Latvia") },
  { value: "lb", label: ("Lebanon") },
  { value: "ls", label: ("Lesotho") },
  { value: "lr", label: ("Liberia") },
  { value: "ly", label: ("Libyan Arab Jamahiriya") },
  { value: "li", label: ("Liechtenstein") },
  { value: "lt", label: ("Lithuania") },
  { value: "lu", label: ("Luxembourg") },
  { value: "mo", label: ("Macao") },
  { value: "mg", label: ("Madagascar") },
  { value: "mw", label: ("Malawi") },
  { value: "my", label: ("Malaysia") },
  { value: "mv", label: ("Maldives") },
  { value: "ml", label: ("Mali") },
  { value: "mt", label: ("Malta") },
  { value: "mq", label: ("Martinique") },
  { value: "mr", label: ("Mauritania") },
  { value: "mu", label: ("Mauritius") },
  { value: "yt", label: ("Mayotte") },
  { value: "mx", label: ("Mexico") },
  { value: "md", label: ("Moldova,Republic of") },
  { value: "mc", label: ("Monaco") },
  { value: "mn", label: ("Mongolia") },
  { value: "me", label: ("Montenegro") },
  { value: "ms", label: ("Montserrat") },
  { value: "ma", label: ("Morocco") },
  { value: "mz", label: ("Mozambique") },
  { value: "mm", label: ("Myanmar") },
  { value: "na", label: ("Namibia") },
  { value: "nr", label: ("Nauru") },
  { value: "np", label: ("Nepal") },
  { value: "nl", label: ("Netherlands") },
  { value: "an", label: ("Netherlands Antilles") },
  { value: "nc", label: ("New Caledonia") },
  { value: "nz", label: ("New Zealand") },
  { value: "ni", label: ("Nicaragua") },
  { value: "ne", label: ("Niger") },
  { value: "ng", label: ("Nigeria") },
  { value: "nu", label: ("Niue") },
  { value: "nf", label: ("Norfolk Island") },
  { value: "kp", label: ("Korea,Democratic People's Republic Of") },
  { value: "mk", label: ("North Macedonia") },
  { value: "no", label: ("Norway") },
  { value: "om", label: ("Oman") },
  { value: "pk", label: ("Pakistan") },
  { value: "ps", label: ("Palestinian Territory, Occupied") },
  { value: "pa", label: ("Panama") },
  { value: "pg", label: ("Papua New Guinea") },
  { value: "py", label: ("Paraguay") },
  { value: "pe", label: ("Peru") },
  { value: "ph", label: ("Philippines") },
  { value: "pn", label: ("Pitcairn") },
  { value: "pl", label: ("Poland") },
  { value: "pt", label: ("Portugal") },
  { value: "qa", label: ("Qatar") },
  { value: "re", label: ("Reunion") },
  { value: "ro", label: ("Romania") },
  { value: "ru", label: ("Russia") },
  { value: "rw", label: ("Rwanda") },
  { value: "ws", label: ("Samoa") },
  { value: "sm", label: ("San Marino") },
  { value: "st", label: ("Sao Tome And Principe") },
  { value: "sa", label: ("Saudi Arabia") },
  { value: "sn", label: ("Senegal") },
  { value: "rs", label: ("Serbia") },
  { value: "sc", label: ("Seychelles") },
  { value: "sl", label: ("Sierra Leone") },
  { value: "sg", label: ("Singapore") },
  { value: "sx", label: ("Sint Maarten") },
  { value: "sk", label: ("Slovakia") },
  { value: "si", label: ("Slovenia") },
  { value: "sb", label: ("Solomon Islands") },
  { value: "so", label: ("Somalia") },
  { value: "za", label: ("South Africa") },
  { value: "gs", label: ("South Georgia And The South Sandwich Islands") },
  { value: "kr", label: ("South Korea") },
  { value: "ss", label: ("South Sudan") },
  { value: "es", label: ("Spain") },
  { value: "lk", label: ("Sri Lanka") },
  { value: "bl", label: ("Saint Barthélemy") },
  { value: "sh", label: ("Saint Helena") },
  { value: "kn", label: ("Saint Kitts And Nevis") },
  { value: "lc", label: ("Saint Lucia") },
  { value: "mf", label: ("Saint Martin") },
  { value: "pm", label: ("Saint Pierre And Miquelon") },
  { value: "vc", label: ("St. Vincent") },
  { value: "sd", label: ("Sudan") },
  { value: "sr", label: ("Suriname") },
  { value: "sj", label: ("Svalbard And Jan Mayen") },
  { value: "se", label: ("Sweden") },
  { value: "ch", label: ("Switzerland") },
  { value: "sy", label: ("Syria") },
  { value: "tw", label: ("Taiwan") },
  { value: "tj", label: ("Tajikistan") },
  { value: "tz", label: ("Tanzania, United Republic Of") },
  { value: "th", label: ("Thailand") },
  { value: "tl", label: ("Timor Leste") },
  { value: "tg", label: ("Togo") },
  { value: "tk", label: ("Tokelau") },
  { value: "to", label: ("Tonga") },
  { value: "tt", label: ("Trinidad and Tobago") },
  { value: "tn", label: ("Tunisia") },
  { value: "tr", label: ("Turkey") },
  { value: "tm", label: ("Turkmenistan") },
  { value: "tc", label: ("Turks and Caicos Islands") },
  { value: "tv", label: ("Tuvalu") },
  { value: "um", label: ("United States Minor Outlying Islands") },
  { value: "ug", label: ("Uganda") },
  { value: "ua", label: ("Ukraine") },
  { value: "ae", label: ("United Arab Emirates") },
  { value: "gb", label: ("United Kingdom") },
  { value: "us", label: ("United States") },
  { value: "uy", label: ("Uruguay") },
  { value: "uz", label: ("Uzbekistan") },
  { value: "vu", label: ("Vanuatu") },
  { value: "va", label: ("Holy See (Vatican City State)") },
  { value: "ve", label: ("Venezuela") },
  { value: "vn", label: ("Vietnam") },
  { value: "wf", label: ("Wallis And Futuna") },
  { value: "eh", label: ("Western Sahara") },
  { value: "ye", label: ("Yemen") },
  { value: "zm", label: ("Zambia") },
  { value: "zw", label: ("Zimbabwe") },
];
var currencyData = [
  { value: "usd", label: ("USD") },
  { value: "cad", label: ("CAD") },
  { value: "dkk", label: ("DKK") },
  { value: "eur", label: ("EUR") },
  { value: "hkd", label: ("HKD") },
  { value: "nzd", label: ("NZD") },
  { value: "gbp", label: ("GBP") },
  { value: "jpy", label: ("JPY") },
  { value: "sgd", label: ("SGD") },
  { value: "sek", label: ("SEK") },
  { value: "aud", label: ("AUD") },
  { value: "other", label: ("other") },
];
var browserData = [
  { value: "internet-explorer", label: ("Internet Explorer") },
  { value: "firefox", label: ("Firefox") },
  { value: "safari", label: ("Safari") },
  { value: "chrome", label: ("Chrome") },
  { value: "edge", label: ("Edge") },
  { value: "opera", label: ("Opera") },
  { value: "netscape", label: ("Netscape") },
  { value: "maxthon", label: ("Maxthon") },
  { value: "konqueror", label: ("Konqueror") },
  { value: "handheld-browser", label: ("Handheld Browser") },
];
var deviceData = [
  { value: "mobile", label: ("Mobile") },
  { value: "desktop", label: ("Desktop") },
  { value: "tablet", label: ("Tablet") },
  { value: "unknown", label: ("Unknown") },
];
var operating_systemData = [
  { value: "windows-10", label: ("Windows 10") },
  { value: "windows-8.1", label: ("Windows 8.1") },
  { value: "windows-8", label: ("Windows 8") },
  { value: "windows-7", label: ("Windows 7") },
  { value: "windows-vista", label: ("Windows vista") },
  {
    value: "windows-server-2003xp-x64",
    label: ("Windows Server 2003/XP x64"),
  },
  { value: "windows-xp", label: ("Windows XP") },
  { value: "windows-2000", label: ("Windows 2000") },
  { value: "windows-me", label: ("Windows ME") },
  { value: "windows-98", label: ("Windows 98") },
  { value: "windows-95", label: ("Windows 95") },
  { value: "windows-3", label: ("Windows 3.11") },
  { value: "mac-os-x", label: ("Mac OS X") },
  { value: "mac-os-9", label: ("Mac OS 9") },
  { value: "linux", label: ("Linux") },
  { value: "ubuntu", label: ("Ubuntu") },
  { value: "iphone", label: ("iPhone") },
  { value: "ipod", label: ("iPod") },
  { value: "ipad", label: ("iPad") },
  { value: "android", label: ("Android") },
  { value: "blackberry", label: ("BlackBerry") },
  { value: "mobile", label: ("Mobile") },
];
var product_conditionsData = [
  { value: "0", label: __("At least one product") },
  { value: "1", label: __("Entire order") },
];
var customer_conditionsData = [
  { value: "3", label: __("Main") },
  { value: "0", label: __("Default") },
  { value: "1", label: __("Secondary") },
  { value: "2", label: __("All") },
];
var custome_addressData = [
  { value: "0", label: __("Default") },
  { value: "1", label: __("Secondary") },
  { value: "2", label: __("All") },
];
var conditionsDataOne = [
  { value: "yes", label: __("Yes") },
  { value: "no", label: __("No") },
];
var fraudAnalysisData = [
  { value: "low", label: ("Low") },
  { value: "medium", label: ("Medium") },
  { value: "high", label: ("High") },
];
var fulfillmentStatusData = [
  { value: "unfulfilled", label: ("Unfulfilled") },
  { value: "partially-fulfilled", label: ("Partially fulfilled") },
  { value: "fulfilled", label: ("Fulfilled") },
];
var orderStatusData = [
  { value: "open", label: ("Open") },
  { value: "archived", label: ("Archived") },
  { value: "cancelled", label: ("Cancelled") },
];
var fulfillmentStatusDataOne = [
  { value: "web", label: ("Online store") },
  { value: "global", label: ("POS") },
];
var weight_unitData = [
  { value: "0", label: ("lb") },
  { value: "1", label: ("oz") },
  { value: "2", label: ("kg") },
  { value: "3", label: ("g") },
];
var carrierData = [
  { value: "none", label: ("None") },
  { value: "4px", label: ("4PX") },
  { value: "apc", label: ("APC") },
  { value: "amazon-logistics-uk", label: ("Amazon Logistics UK") },
  { value: "amazon-logistics-us", label: ("Amazon Logistics US") },
  { value: "anjun-logistics", label: ("Anjun Logistics") },
  { value: "australia-post", label: ("Australia Post") },
  { value: "bluedart", label: ("Bluedart") },
  { value: "canada-post", label: ("Canada Post") },
  { value: "china-post", label: ("China Post") },
  { value: "chukou1", label: ("Chukou1") },
  { value: "correios", label: ("Correios") },
  { value: "dhl-express", label: ("DHL Express") },
  { value: "dhl-ecommerce", label: ("DHL eCommerce") },
  { value: "dhl-ecommerce-asia", label: ("DHL eCommerce Asia") },
  { value: "dpd", label: ("DPD") },
  { value: "dpd-local", label: ("DPD Local") },
  { value: "dpd-uk", label: ("DPD UK") },
  { value: "delhivery", label: ("Delhivery") },
  { value: "eagle", label: ("Eagle") },
  { value: "fsc", label: ("FSC") },
  { value: "fedex", label: ("FedEx") },
  { value: "gls", label: ("GLS") },
  { value: "gls-(us)", label: ("GLS (US)") },
  { value: "globegistics", label: ("Globegistics") },
  { value: "japan-post-(en)", label: ("Japan Post (EN)") },
  { value: "japan-post-(ja)", label: ("Japan Post (JA)") },
  { value: "la-poste", label: ("La Poste") },
  { value: "new-zealand-post", label: ("New Zealand Post") },
  { value: "newgistics", label: ("Newgistics") },
  { value: "postnl", label: ("PostNL") },
  { value: "postnord", label: ("PostNord") },
  { value: "purolator", label: ("Purolator") },
  { value: "royal-mail", label: ("Royal Mail") },
  { value: "sf-express", label: ("SF Express") },
  { value: "sfc-fulfillment", label: ("SFC Fulfillment") },
  { value: "sagawa-(en)", label: ("Sagawa (EN)") },
  { value: "sagawa-(ja)", label: ("Sagawa (JA)") },
  { value: "singaporepost", label: ("Singapore Post") },
  { value: "tnt", label: ("TNT") },
  { value: "ups", label: ("UPS") },
  { value: "usps", label: ("USPS") },
  { value: "whistl", label: ("Whistl") },
  { value: "yamato-(en)", label: ("Yamato (EN)") },
  { value: "yamato-(ja)", label: ("Yamato (JA)") },
  { value: "yunexpress", label: ("YunExpress") },
  { value: "other", label: ("Other") },
];
var ConditionData = [
  { value: "1", label: __("Amount") },
  { value: "2", label: __("Percentage") },
];
var ConditionDatas = [
  { value: "minute", label: __("Minutes") },
  { value: "hour", label: __("Hours") },
  { value: "day", label: __("Days") },
  { value: "week", label: __("Weeks") },
  { value: "month", label: __("Months") },
  { value: "year", label: __("Years") }
];
var paymentData = [
  { value: "paypal", label: ("PayPal Express Checkout") },
  { value: "cash on delivery (cod)", label: ("Cash on Delivery (COD)") },
  { value: "shopify_payments", label: ("Shopify Payments") },
  { value: "bank deposit", label: ("Bank Deposit") },
  {
    value: "cartão_de_crédito_e_boleto_via_ebanx",
    label: ("Cartão de Crédito e Boleto via EBANX"),
  },
  { value: "cash", label: ("Cash") },
  { value: "amazon_payments", label: ("Amazon Pay") },
  {
    value: "pago_con_tarjeta_de_crédito_y_débito_by_paypal",
    label: ("Pago con tarjeta de crédito y débito"),
  },
  { value: "stripe", label: ("Stripe") },
  { value: "Stripe Connect", label: ("Stripe Connect") },
  { value: "rechnung_mit_klarna", label: ("Rechnung mit Klarna") },
  { value: "amazon", label: ("Amazon") },
  { value: "cash_on_delivery", label: ("Cash on Delivery") },
  { value: "cashfree", label: ("Cashfree") },
  { value: "amazon_marketplace", label: ("Amazon Marketplace") },
  { value: "wanelo", label: ("Wanelo") },
  { value: "viabill_north_america", label: ("ViaBill North America") },
  { value: "other", label: ("Other") },
];
var paymentSatatusData = [
  { value: "authorized", label: ("Authorized") },
  { value: "paid", label: ("Paid") },
  { value: "partially-refunded", label: ("Partially refunded") },
  { value: "partially-paid", label: ("Partially paid") },
  { value: "pending", label: ("Pending") },
  { value: "refunded", label: ("refunded") },
  { value: "npaid", label: ("Unpaid") },
  { value: "voided", label: ("Voided") },
];
var shipmentData = [
  { value: "label-printed", label: ("Label printed") },
  { value: "label-purchased", label: ("Label purchased") },
  { value: "attempted-delivery", label: ("Attempted delivery") },
  { value: "ready-for-pickup", label: ("Ready for pickup") },
  { value: "confirmed", label: ("Confirmed") },
  { value: "in-transit", label: ("In transit") },
  { value: "out-for-delivery", label: ("Out for delivery") },
  { value: "delivered", label: ("Delivered") },
  { value: "failure", label: ("Failure") },
];
var salesData = [
  { value: "online store", label: ("Online store") },
  { value: "pos", label: ("POS") },
  {
    value: "reconvert-post-purchase-upsell",
    label: ("Reconvert post purchase upsell"),
  },
  { value: "other", label: ("Other") },
];
var languageData = [
  { value: "af", label: ("Afrikaans") },
  { value: "ak", label: ("Akan") },
  { value: "sq", label: ("Albanian") },
  { value: "am", label: ("Amharic") },
  { value: "ar", label: ("Arabic") },
  { value: "an", label: ("Aragonese") },
  { value: "hy", label: ("Armenian") },
  { value: "ast", label: ("Asturian") },
  { value: "az", label: ("Azerbaijani") },
  { value: "eu", label: ("Basque") },
  { value: "be", label: ("Belarusian") },
  { value: "bn", label: ("Bengali") },
  { value: "bs", label: ("Bosnian") },
  { value: "br", label: ("Breton") },
  { value: "bg", label: ("Bulgarian") },
  { value: "ca", label: ("Catalan") },
  { value: "ckb", label: ("Central Kurdish") },
  { value: "zh", label: ("Chinese") },
  { value: "co", label: ("Corsican") },
  { value: "hr", label: ("Croatian") },
  { value: "cs", label: ("Czech") },
  { value: "da", label: ("Danish") },
  { value: "nl", label: ("Dutch") },
  { value: "en", label: ("English") },
  { value: "eo", label: ("Esperanto") },
  { value: "et", label: ("Estonian") },
  { value: "fo", label: ("Faroese") },
  { value: "fil", label: ("Filipino") },
  { value: "fi", label: ("Finnish") },
  { value: "fr", label: ("French") },
  { value: "gl", label: ("Galician") },
  { value: "ka", label: ("Georgian") },
  { value: "de", label: ("German") },
  { value: "el", label: ("Greek") },
  { value: "gn", label: ("Guarani") },
  { value: "gu", label: ("Gujarati") },
  { value: "ha", label: ("Hausa") },
  { value: "haw", label: ("Hawaiian") },
  { value: "he", label: ("Hebrew") },
  { value: "hi", label: ("Hindi") },
  { value: "hu", label: ("Hungarian") },
  { value: "is", label: ("Icelandic") },
  { value: "id", label: ("Indonesian") },
  { value: "ia", label: ("Interlingua") },
  { value: "ga", label: ("Irish") },
  { value: "it", label: ("Italian") },
  { value: "ja", label: ("Japanese") },
  { value: "jv", label: ("Javanese") },
  { value: "kn", label: ("Kannada") },
  { value: "kk", label: ("Kazakh") },
  { value: "km", label: ("Khmer") },
  { value: "ko", label: ("Korean") },
  { value: "ku", label: ("Kurdish") },
  { value: "ky", label: ("Kyrgyz") },
  { value: "lo", label: ("Lao") },
  { value: "la", label: ("Latin") },
  { value: "lv", label: ("Latvian") },
  { value: "ln", label: ("Lingala") },
  { value: "lt", label: ("Lithuanian") },
  { value: "mk", label: ("Macedonian") },
  { value: "ms", label: ("Malay") },
  { value: "ml", label: ("Malayalam") },
  { value: "mt", label: ("Maltese") },
  { value: "mi", label: ("Maori") },
  { value: "mr", label: ("Marathi") },
  { value: "mn", label: ("Mongolian") },
  { value: "ne", label: ("Nepali") },
  { value: "no", label: ("Norwegian") },
  { value: "nb", label: ("Norwegian (Bokmål)") },
  { value: "nn", label: ("Norwegian Nynorsk") },
  { value: "oc", label: ("Occitan") },
  { value: "or", label: ("Odia") },
  { value: "om", label: ("Oromo") },
  { value: "ps", label: ("Pashto") },
  { value: "fa", label: ("Persian") },
  { value: "pl", label: ("Polish") },
  { value: "pt", label: ("Portuguese") },
  { value: "pa", label: ("Punjabi") },
  { value: "qu", label: ("Quechua") },
  { value: "ro", label: ("Romanian") },
  { value: "rm", label: ("Romansh") },
  { value: "ru", label: ("Russian") },
  { value: "gd", label: ("Scottish Gaelic") },
  { value: "sr", label: ("Serbian") },
  { value: "sh", label: ("Serbo-Croatian") },
  { value: "sn", label: ("Shona") },
  { value: "sd", label: ("Sindhi") },
  { value: "si", label: ("Sinhala") },
  { value: "sk", label: ("Slovak") },
  { value: "sl", label: ("Slovenian") },
  { value: "so", label: ("Somali") },
  { value: "st", label: ("Southern Sotho") },
  { value: "es", label: ("Spanish") },
  { value: "su", label: ("Sundanese") },
  { value: "sw", label: ("Swahili") },
  { value: "sv", label: ("Swedish") },
  { value: "tg", label: ("Tajik") },
  { value: "ta", label: ("Tamil") },
  { value: "tt", label: ("Tatar") },
  { value: "te", label: ("Telugu") },
  { value: "th", label: ("Thai") },
  { value: "ti", label: ("Tigrinya") },
  { value: "to", label: ("Tongan") },
  { value: "tr", label: ("Turkish") },
  { value: "tk", label: ("Turkmen") },
  { value: "tw", label: ("Twi") },
  { value: "uk", label: ("Ukrainian") },
  { value: "ur", label: ("Urdu") },
  { value: "ug", label: ("Uyghur") },
  { value: "uz", label: ("Uzbek") },
  { value: "vi", label: ("Vietnamese") },
  { value: "wa", label: ("Walloon") },
  { value: "cy", label: ("Welsh") },
  { value: "fy", label: ("Western Frisian") },
  { value: "xh", label: ("Xhosa") },
  { value: "yi", label: ("Yiddish") },
  { value: "yo", label: ("Yoruba") },
  { value: "zu", label: ("Zulu") },
  { value: "bh", label: ("bh") },
  { value: "mo", label: ("mo") },
  { value: "other", label: ("Other") },
];
export {
  category,
  customer,
  order,
  product,
  other,
  countryData,
  currencyData,
  browserData,
  deviceData,
  operating_systemData,
  product_conditionsData,
  customer_conditionsData,
  custome_addressData,
  conditionsDataOne,
  fraudAnalysisData,
  fulfillmentStatusData,
  fulfillmentStatusDataOne,
  weight_unitData,
  carrierData,
  ConditionData,
  ConditionDatas,
  paymentData,
  paymentSatatusData,
  shipmentData,
  salesData,
  orderStatusData,
  languageData,
};