import React, { Fragment } from "react";
import Select, { components } from "react-select";

const Option = (props) => {
  console.log(props)
  return (
    <Fragment>
      <components.Option {...props}>{props.children}</components.Option>
    </Fragment>
  );
};

const CustomSelect = ({
  options,
  changeOptionsData,
  fetchingData,
  defaultValue,
  onChange,
  onInputChange,
  onMenuOpen,
}) => {
  return (
    <div>
      <Select
        options={options}
        components={{ Option }}
        onChange={onChange}
        defaultValue={defaultValue}
        isMulti
        onInputChange ={onInputChange}
        onMenuOpen ={onMenuOpen }
      />
    </div>
  );
};
export default CustomSelect;
