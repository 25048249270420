import React from "react";

class DeleteTrigger extends React.PureComponent {
    render() {
        return (
            <svg className="Polaris-Icon__Svg" viewBox="0 0 20 20" >
                <path
                    d="M16 6a1 1 0 1 1 0 2h-1v9a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V8H4a1 1 0 1 1 0-2h12zM9 4a1 1 0 1 1 0-2h2a1 1 0 1 1 0 2H9zm2 12h2V8h-2v8zm-4 0h2V8H7v8z" fill="#000" fillRule="evenodd"
                ></path>
            </svg>
        );
    }
}

export default DeleteTrigger;
