import axios from 'axios';
const api_url = require('./config');
const url = api_url.api_url;
class Service {
  constructor() {
    let service = axios.create({
      headers: {
        rct_node_port: true
      }
    });
    service.interceptors.response.use(this.handleSuccess, this.handleError);
    this.service = service;
  }

  handleSuccess(response) {
    return response;
  }

  handleError = (error) => {
    /* switch (error.response.status) {
         case 401:
           this.redirectTo(document, '/')
           break;
         case 404:
           this.redirectTo(document, '/404')
           break;
         default:
           this.redirectTo(document, '/500')
           break;
       } */
    return Promise.reject(error)
  }

  redirectTo = (document, path) => {
    document.location = path
  }

  get(path, callback) {
    return this.service.request({
      method: 'get',
      url: url + path,
      responseType: 'json'
    }).then((response) => callback(response.status, response.data));
  }

  patch(path, payload, callback) {
    return this.service.request({
      method: 'PATCH',
      url: url + path,
      responseType: 'json',
      data: payload
    }).then((response) => callback(response.status, response.data));
  }

  post(path, payload, callback) {
    return this.service.request({
      method: 'POST',
      url: url + path,
      responseType: 'json',
      data: payload
    }).then((response) => callback(response.status, response.data));
  }
}

export default Service;