import React from "react";
import { Collapsible, TextContainer, Button } from "@shopify/polaris";
import { _ as __ } from 'ttag';
class AnootationDetailsThankyou extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <>
                <Button onClick={(e) => this.props.accordion_handleToggle(e, this.props.ele, this.props.i, true, this.props.k)} ariaExpanded={this.props.ele.open} ariaControls="basic-collapsible" id="accordian_btn" >
                    <div className="tooltip_title">
                        {this.props.ele.parent_id === 0 ? <h4>{this.props.ele.name}</h4> : <h4>{this.props.ele.name}</h4>}
                        {this.props.ele.open?
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                            <path fillRule="evenodd" clipRule="evenodd" d="M18 15.1147L12 9.16929L6 15.1147L18 15.1147Z" fill="#5C5F62"/>
                        </svg>
                        :
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                            <path fillRule="evenodd" clipRule="evenodd" d="M6 10.3584L12 16.3039L18 10.3584H6Z" fill="#5C5F62"/>
                        </svg>}
                        {/* <Icon source={this.props.ele.open ? CaretUpMinor : DropdownMinor} /> */}
                        <span className="tooltiptext down_tooltip">{this.props.ele.name}</span>
                    </div>
                </Button>
                <Collapsible open={this.props.ele.open} id="basic-collapsible" transition={{ duration: '150ms', timingFunction: 'ease' }} >
                    <TextContainer>
                        <div className="accordian_pannel">
                            <div className="accrodian_text">
                                <p>{__('Impressions')}: </p>
                                <p className="thanku_size">{this.props.ele.impressions == null || this.props.ele.impressions === 0 || this.props.ele.impressions === undefined ? 0 : this.props.ele.impressions}</p>
                            </div>
                            <div className="accrodian_text">
                                <p>{__('Orders')}: </p>
                                <p className="thanku_size">{this.props.ele.orders === 0 || this.props.ele.orders === undefined ? "-" : this.props.ele.orders}</p>
                            </div>
                            <div className="accrodian_text">
                                <p>{__('Revenue')}: </p>
                                <p className="thanku_size">{this.props.ele.Revenue === 0 || this.props.ele.Revenue === undefined || this.props.ele.Revenue === null ? "-" : this.props.ele.Revenue}</p>
                            </div>
                            <div className="accrodian_text">
                                <p>{__('CVR')}: </p>
                                <p className="thanku_size">{this.props.ele.CVR === 0 || this.props.ele.CVR === undefined ? "-" : parseFloat(this.props.ele.CVR).toFixed(2)}%</p>
                            </div>
                        </div>
                    </TextContainer>
                </Collapsible>
            </>
        );
    }
}

export default AnootationDetailsThankyou;
