import React from "react";
import { Select2 } from "select2-react-component";
import "select2-component/dist/select2.min.css"
import { Icon, Collapsible, Button } from "@shopify/polaris";
import { ChevronDownMinor, ChevronUpMinor } from "@shopify/polaris-icons";
import { _ as __ } from 'ttag';
import Service from "../../service";

class FallbackTemplate extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
    };
    this.service = new Service();
  }
  render() {
    return (
      <>
        <div className={this.props.expandView ? "funnel_app fallback_template is_bottom is_open" : "funnel_app fallback_template is_bottom"}>
            <Button onClick={this.props.handleToggle} ariaExpanded={this.props.expandView} ariaControls="basic-collapsible" id="accordian_btn">
              <div className="trigeer_heading">
                <h3>{__('FALLBACK THANK YOU PAGE')}</h3>
                <Icon source={this.props.expandView ? ChevronDownMinor : ChevronUpMinor} />
              </div>
            </Button>
            <Collapsible open={this.props.expandView} id="basic-collapsible" transition={{ duration: '150ms', timingFunction: 'ease' }} expandOnPrint>

              <div className="fallback_desc">
                <p>{__('Will be displayed in case of any restrictions by Shopify mentioned on')} <a href="https://shopify.dev/apps/checkout/post-purchase#limitations-and-considerations" target="blank"> {__("this page.")}</a></p>
                <p>{__("We will support any of these restrictions once Shopify allows so.")}</p>
                {/* <p>{__('Will be displayed in case')} <a href="https://partners.shopify.com/" target="blank">{__('unsupported payment gateways')}</a> {__('are used during checkout. If your payment gateway isn’t supported please')}<a href="https://www.shopify.in/contact" target="blank"> {__('contact Shopify.')}</a></p> */}
                <div>
                  <div id="space_txt" className="drop-down-select2">
                    <p className="select_top">{__('Fallback thank you page')}</p>
                    {this.props.anaytics_data !== '' ?
                      <Select2 data={this.props.anaytics_data}
                        placeholder={__("Select Template")}
                        value={this.props.value !== null ? this.props.value.value : ''}
                        open={this.props.openfallback}
                        update={this.props.handleChangeTemplateFallback}
                      >
                      </Select2> : ""}
                  </div>
                  {this.props.anaytics_data_value ?
                    <div className="accordian_pannel fallback_acc">
                      <div>
                        <div className="accrodian_text">
                          <p>{__('Impressions')}: </p>
                          <p>{this.props.anaytics_data_value.impressions == null || this.props.anaytics_data_value.impressions === 0 || this.props.anaytics_data_value.impressions === undefined ? 0 : this.props.anaytics_data_value.impressions}</p>
                        </div>
                        <div className="accrodian_text">
                          <p>{__('Orders')}: </p>
                          <p>{this.props.anaytics_data_value.orders === 0 || this.props.anaytics_data_value.orders === undefined ? "-" : this.props.anaytics_data_value.orders}</p>
                        </div>
                      </div>
                      <div>
                        <div className="accrodian_text">
                          <p>{__('Revenue')}:</p>
                          <p>{this.props.anaytics_data_value.Revenue !== null ? this.props.anaytics_data_value.Revenue : "-"}</p>
                        </div>
                        <div className="accrodian_text">
                          <p>{__('CVR')}: </p>
                          <p>{this.props.anaytics_data_value.CVR !== null ? parseFloat(this.props.anaytics_data_value.CVR).toFixed(2) : "-"}%</p>
                        </div>
                      </div>
                    </div> : ""}
                </div>
              </div>
            </Collapsible>
        </div>
      </>
    );
  }
}

export default FallbackTemplate;
