const buttonDataId = (props) =>{
    const {text,dataAttribute,id} = props
    const data = document.querySelectorAll(id)
    data.forEach((i,index)=>{
        if(i.innerText == text){
            i.setAttribute('data-id',dataAttribute)
        }else{
            if(props.text == 'Select'){
                i.setAttribute('data-id',i.innerText)
            }
        }
    })

}
const DataId = (props) =>{
    try{
        if(props.text =='#accordian_btn'){
            document.querySelector("#accordian_btn").setAttribute("data-id",'offer_dropdown')
        }else if(props.text == 'Save' ){
            buttonDataId(props)
        }else if(props.text == '#compactview_btn'){
            document.querySelector('#compactview_btn').setAttribute("data-id",'expand_view')
        }else if(props.text == 'Select'){
            buttonDataId(props)
        }
    }catch(err){
      
    }
    
}

export default DataId