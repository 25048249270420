import React from "react";
import Select from "react-select";
import { _ as __} from 'ttag';
class Dynamicthankyou extends React.PureComponent {
    render() {
        return (
            <div>
                <h3>{__('select template')}</h3>
                <div className="lastsection_content" ref={this.props.wrapperRef}>
                    <Select
                        className="dynamic_dropDown"
                        value={this.props.selectedOption}
                        onChange={this.props.handleChange_thanks}
                        options={this.props.templateDate}
                       formatOptionLabel={this.props.formatOptionLabel}
                        defaultMenuIsOpen={true}
                    />
                </div>
            </div>
        );
    }
}

export default Dynamicthankyou;
