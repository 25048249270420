import React from "react";
import { Modal } from "@shopify/polaris";

class CommanModal extends React.PureComponent {
 render() {
   var reactEditor = this.props.sampleActiveURL+"&is_editor=1";
    return (
      <Modal
        large
        limitHeight={false}
        open={this.props.sampleActive}
        onClose={this.props.close_sampleCloseModal}
        iFrameName="Edit_offer"
        loading={false}
      >
        <Modal.Section>
          <iframe
            title="Edit_offer"
            name="Edit_offer"
            id="Edit_offer"
            src={reactEditor}
            width="100%"
            height="750"
            onLoad={this.props.onMyFrameLoad}
          ></iframe>
        </Modal.Section>
      </Modal>
    );
  }
}

export default CommanModal;
